<template>
    <div class="pricing">
        <h1> This is the pricing page </h1>
    </div>
</template>

<script>
export default {
    name: 'Pricing'
}
</script>

<style>

</style>